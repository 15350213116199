/*!
 *
 *  menu.ts
 *
 */
var SiteMenu = /** @class */ (function () {
    function SiteMenu(options) {
        var _this = this;
        this.toggleMenu = function () {
            var isMenuOpen = _this.container.getAttribute('aria-hidden') === 'false';
            var html = document.querySelector('html');
            var scrollControll = _this.scrollControll.bind(_this);
            var eventType = isMenuOpen ? 'removeEventListener' : 'addEventListener';
            var passive = { passive: false };
            _this.btn.setAttribute('aria-pressed', "".concat(!isMenuOpen));
            _this.container.setAttribute('aria-hidden', "".concat(isMenuOpen));
            html.style.overflow = isMenuOpen ? 'auto' : 'hidden';
            document[eventType]('touchmove', scrollControll, passive);
        };
        this.closeMenu = function () {
            _this.btn.setAttribute('aria-pressed', 'false');
            _this.container.setAttribute('aria-hidden', 'true');
            document.querySelector('html').style.overflow = 'auto';
            document.removeEventListener('touchmove', _this.scrollControll);
        };
        this.scrollControll = function (e) {
            var isMenuOpen = _this.container.getAttribute('aria-hidden') === 'false';
            var inner = e.target.closest(_this.containerName);
            if (isMenuOpen) {
                if (inner) {
                    if (inner.scrollHeight > inner.clientHeight) {
                        e.stopPropagation();
                    }
                    else {
                        e.preventDefault();
                    }
                }
                else {
                    e.preventDefault();
                }
            }
        };
        this.btn = document.querySelector(options.btn);
        this.container = document.querySelector(options.container);
        this.containerName = options.container;
        this.btn.addEventListener('click', this.toggleMenu);
        document.addEventListener('click', function (e) {
            if (_this.container.getAttribute('aria-hidden') === 'false'
                && !e.target.closest(_this.containerName)
                && !e.target.closest(options.btn)) {
                _this.closeMenu();
            }
        });
        window.addEventListener('viewportChanged', this.viewportCheck.bind(this));
        this.viewportCheck();
    }
    SiteMenu.prototype.viewportCheck = function () {
        var shouldHide = ['phone', 'tablet'].includes(util.viewport);
        this.container.setAttribute('aria-hidden', "".concat(shouldHide));
    };
    return SiteMenu;
}());
export default SiteMenu;
