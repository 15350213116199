/*!
 *
 * app.js
 *
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*** ./modules **/
import scroll2hash from 'scroll2hash';
import Linker from 'linker';
import Menu from 'menu';
import smoothScroll from 'smooth-scroll';
import accordion from 'accordion';
scroll2hash();
function init() {
    Linker();
    smoothScroll({
        ignore: '[data-ignore-link], .huga a'
    });
    var menu = new Menu({
        btn: '#menuBtn',
        container: '#menuContainer'
    });
    var globalLinks = document.querySelectorAll('.p-site-global a[href^="#"]');
    if (globalLinks.length) {
        globalLinks.forEach(function (link) {
            link.addEventListener('click', function (e) {
                menu.closeMenu();
            });
        });
    }
    ;
    var closeBtn = document.querySelector('.p-site-menu__close');
    if (closeBtn) {
        closeBtn.addEventListener('click', function (e) {
            e.preventDefault();
            menu.closeMenu();
        });
    }
    ;
    accordion('.c-faq', {
        containerSelector: '.c-faq__item',
        headSelector: '.c-faq__head',
        bodySelector: '.c-faq__body'
    });
    initMv();
    // initZoom();
    initPhoto();
}
;
function initMv() {
    return __awaiter(this, void 0, void 0, function () {
        var swiperElem, mv;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    swiperElem = document.querySelector('#mvSlider');
                    if (!swiperElem) return [3 /*break*/, 2];
                    return [4 /*yield*/, import(
                        /* webpackChunkName: "mv" */ 'mv')];
                case 1:
                    mv = (_a.sent()).default;
                    mv('#mvSlider');
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
// async function initZoom() {
// 	const zoomBtn = document.querySelectorAll('.c-btn.-zoom');
// 	if (zoomBtn.length > 0) {
// 		const { default: zoom } = await import(
// 			/* webpackChunkName: "zoom" */ 'zoom'
// 		);
// 		zoom('.c-btn.-zoom');
// 	}
// }
function initPhoto() {
    return __awaiter(this, void 0, void 0, function () {
        var swiperElem, photo;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    swiperElem = document.querySelector('#photoSlider');
                    if (!swiperElem) return [3 /*break*/, 2];
                    return [4 /*yield*/, import(
                        /* webpackChunkName: "photo" */ 'photo')];
                case 1:
                    photo = (_a.sent()).default;
                    photo('#photoSlider');
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
// async function initTab() {
// 	const tabElem = document.querySelector('.c-tab');
// 	if (tabElem) {
// 		const { default: Tab } = await import(
// 			/* webpackChunkName: "tab" */ 'tab'
// 		);
// 		Tab('.c-tab')
// 	}
// }
// async function initAccordion() {
// 	const accordionElem = document.querySelector('.p-faq');
// 	if (accordionElem) {
// 		const { default: Accordion } = await import(
// 			/* webpackChunkName: "accordion" */ 'accordion'
// 		);
// 		Accordion('.p-faq', {
// 			containerSelector: '.p-faq__item',
// 			headSelector: '.p-faq__head',
// 			bodySelector: '.p-faq__body'
// 		});
// 	}
// }
// async function initScrollAnimation() {
// 	const scrollAnimationElem = document.querySelector('.u-sa');
// 	if (scrollAnimationElem) {
// 		const { default: ScrollAnimation } = await import(
// 			/* webpackChunkName: "scroll-animation" */ 'scroll-animation'
// 		);
// 		ScrollAnimation();
// 	}
// }
document.readyState !== "loading" ? init() : document.addEventListener("DOMContentLoaded", init);
