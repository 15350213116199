/*!
 *
 *  accordion.js
 *
 *  @template: `
        <div class="wrapper">
            <details aria-expanded="false" data-accordion-container>
                <summary aria-pressed="false" aria-controls="body" data-accordion-head>HEAD</summary>
                <div id="body" data-accordion-body>
                    <div class="inner">
                        <p>CONTENT</p>
                    </div>
                </div>
            </details>
        </div>
 *  `
 */
var EASING = {
    'easeOutQuad': 'cubic-bezier(0.5, 1, 0.89, 1)',
    'easeOutQuart': 'cubic-bezier(0.25, 1, 0.5, 1)',
    'easeOutExpo': 'cubic-bezier(0.16, 1, 0.3, 1)',
};
var AccordionCore = /** @class */ (function () {
    function AccordionCore(elem, option) {
        if (option === void 0) { option = {}; }
        var _this = this;
        this.handleHeadClick = function (e) {
            e.preventDefault();
            if (_this.head.getAttribute('aria-pressed') === 'false') {
                _this.open();
            }
            else {
                _this.close();
            }
        };
        this.handleDetailsToggle = function (e) {
            if (_this.elem.open && _this.head.getAttribute('aria-pressed') === 'false') {
                _this.open();
            }
            if (!_this.elem.open && _this.head.getAttribute('aria-pressed') === 'true') {
                _this.close();
            }
        };
        this.handleBodyTransitionEnd = function (e) {
            if (e.propertyName === 'height') {
                _this.body.style.transition = '';
                if (_this.head.getAttribute('aria-pressed') === 'false') {
                    _this.elem.removeAttribute('open');
                }
                else {
                    _this.elem.setAttribute('aria-expanded', 'true');
                    _this.body.style.height = '';
                }
            }
        };
        this.elem = elem;
        this.headSelector = option.headSelector || '[data-accordion-head]';
        this.bodySelector = option.bodySelector || '[data-accordion-body]';
        this.speed = option.speed || 700;
        this.easing = option.easing || 'easeOutExpo';
        this.head = this.elem.querySelector(this.headSelector);
        this.body = this.elem.querySelector(this.bodySelector);
        this.isDetails = this.elem.tagName === 'DETAILS';
        if (util.viewport === 'phone' && option.spClose) {
            this.close();
        }
        ;
        this.init();
    }
    AccordionCore.prototype.init = function () {
        this.head.addEventListener('click', this.handleHeadClick);
        this.body.addEventListener('transitionend', this.handleBodyTransitionEnd);
        if (this.isDetails) {
            this.elem.addEventListener('toggle', this.handleDetailsToggle);
        }
        ;
    };
    AccordionCore.prototype.open = function () {
        var targetChild = this.body.children[0];
        this.head.setAttribute('aria-pressed', 'true');
        if (this.isDetails) {
            this.elem.setAttribute('open', 'true');
        }
        ;
        var h = targetChild.offsetHeight;
        this.body.style.transition = "height ".concat(this.speed, "ms ").concat(EASING[this.easing]);
        this.body.style.height = "".concat(h, "px");
    };
    AccordionCore.prototype.close = function () {
        var _this = this;
        var targetChild = this.body.children[0];
        var h = targetChild.offsetHeight;
        this.head.setAttribute('aria-pressed', 'false');
        this.elem.setAttribute('aria-expanded', 'false');
        this.body.style.height = "".concat(h, "px");
        setTimeout(function () {
            _this.body.style.transition = "height ".concat(_this.speed, "ms ").concat(EASING[_this.easing]);
            _this.body.style.height = '0';
        }, 10);
    };
    return AccordionCore;
}());
export default function Accordion(elemName, option) {
    if (option === void 0) { option = {}; }
    var accordionElements = document.querySelectorAll(elemName);
    var containerSelector = option.containerSelector || '[data-accordion-container]';
    for (var i = 0; i < accordionElements.length; i++) {
        var accordionItems = accordionElements[i].querySelectorAll(containerSelector);
        accordionItems.forEach(function (item) {
            if (util.viewport === 'phone' && option.spClose) {
                item.removeAttribute('open');
            }
            ;
            new AccordionCore(item, option);
        });
    }
}
