/*!
 *
 * utility.ts
 *
 */
var BREAKPOINTS = {
    phone: 768,
    tablet: 992,
    desktop: 1220,
    largeDesktop: Infinity,
};
var gap = {
    selector: 'header',
    spselector: 'header',
    viewport: {
        phone: true,
        tablet: true,
        desktop: true,
        largeDesktop: true,
    },
};
var getViewport = function (ww) {
    if (ww < BREAKPOINTS.phone) {
        return 'phone';
    }
    else if (ww < BREAKPOINTS.tablet) {
        return 'tablet';
    }
    else if (ww < BREAKPOINTS.desktop) {
        return 'desktop';
    }
    else {
        return 'largeDesktop';
    }
};
var setScrollGap = function (viewport) {
    var gapSelector = viewport === 'phone' ? gap.spselector : gap.selector;
    var gapElement = document.getElementById(gapSelector);
    var hasGapElement = gapElement !== null;
    var gapHeight = hasGapElement ? gapElement.offsetHeight : 0;
    var gapMargin = hasGapElement && viewport !== 'phone' ? 32 : 16;
    return gapHeight + gapMargin;
};
var getScrollbarWidth = function () {
    var element = document.createElement('div');
    element.style.visibility = 'hidden';
    element.style.overflow = 'scroll';
    document.body.appendChild(element);
    var scrollbarWidth = element.offsetWidth - element.clientWidth;
    document.body.removeChild(element);
    return scrollbarWidth;
};
var html = document.documentElement;
var setSize = function () {
    var vh = window.innerHeight * 0.01;
    html.style.setProperty('--vh', "".concat(vh, "px"));
    var scrollbarWidth = getScrollbarWidth();
    if (scrollbarWidth > 0) {
        html.style.setProperty('--scroll-bar', "".concat(scrollbarWidth, "px"));
    }
};
// const telLinkManager = (): void => {
// 	const telLinks = document.querySelectorAll<HTMLElement>(`a[href^="tel:"]`);
// 	const isPhone = /iPhone|Android/.test(navigator.userAgent)
// 	if (isPhone) {
// 		telLinks.forEach((link: HTMLElement) => {
// 			link.classList.add('u-tel', '-enabled');
// 		});
// 	} else {
// 		telLinks.forEach((link: HTMLElement) => {
// 			link.classList.add('u-tel', '-disabled');
// 			link.addEventListener('click', (e) => {
// 				e.preventDefault();
// 			});
// 		});
// 	};
// };
var handleResize = function () {
    var newViewport = getViewport(window.innerWidth);
    if (util.viewport !== newViewport) {
        util.viewport = newViewport;
        window.dispatchEvent(viewportChanged);
    }
    util.scrollGap = setScrollGap(newViewport);
    setSize();
};
var handleLoad = function () {
    util.scrollGap = setScrollGap(util.viewport);
    // telLinkManager();
    setSize();
};
var initUtil = function () {
    var initialViewport = getViewport(window.innerWidth);
    var util = {
        ww: window.innerWidth,
        wh: window.innerHeight,
        hash: location.hash,
        viewport: initialViewport,
        scroller: 'html,body',
        scrollGap: setScrollGap(initialViewport),
        isTouchDevice: 'ontouchstart' in window,
    };
    if (util.isTouchDevice) {
        var body = document.body;
        body.setAttribute('ontouchstart', '');
    }
    return util;
};
var util = initUtil();
var viewportChanged = new CustomEvent('viewportChanged');
window.addEventListener('resize', handleResize, { passive: true });
document.readyState !== "loading" ? handleLoad() : document.addEventListener("DOMContentLoaded", handleLoad);
window.addEventListener('load', handleLoad, { passive: true });
export default util;
