/*!
 *
 *  scroll2hash.js
 *
 */
var scroll2hash = function () {
    var hash = util.hash;
    if (hash.length > 0 && !hash.includes('/')) {
        location.hash = "".concat(hash, "_");
        window.addEventListener('load', function () {
            var target = document.querySelector(hash);
            if (target) {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                var top_1 = target.getBoundingClientRect().top + scrollTop - util.scrollGap;
                setTimeout(function () {
                    window.scrollTo({ top: top_1, behavior: 'auto' });
                }, 10);
                history.replaceState(null, '', util.hash);
            }
            else {
                history.replaceState(null, '', util.hash);
            }
        });
    }
};
export default scroll2hash;
