export default function Linker(options) {
    var _a, _b;
    var settings = {
        target: (_a = options === null || options === void 0 ? void 0 : options.target) !== null && _a !== void 0 ? _a : 'a',
        excludedDirectories: (_b = options === null || options === void 0 ? void 0 : options.excludedDirectories) !== null && _b !== void 0 ? _b : [],
    };
    var domain = window.location.hostname;
    var links = Array.from(document.querySelectorAll(settings.target));
    links.forEach(function (link) {
        if (isLinkTargetable(link)) {
            link.setAttribute('target', '_blank');
            if (hasImageElement(link)) {
                link.setAttribute('data-no-icon', 'true');
            }
        }
    });
    function isLinkTargetable(link) {
        var _a;
        var href = link.href;
        var extension = (_a = href.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (!extension || ['pdf', 'xls', 'xlsx', 'doc', 'docx'].includes(extension)) {
            return false;
        }
        var isSameDomain = href.startsWith("http://".concat(domain)) || href.startsWith("https://".concat(domain));
        if (isSameDomain) {
            return false;
        }
        var excludedDirs = settings.excludedDirectories;
        if (excludedDirs.length > 0 && excludedDirs.some(function (dir) { return href.includes("/".concat(dir, "/")); })) {
            return false;
        }
        if (/^tel:/.test(href)) {
            return false;
        }
        ;
        return true;
    }
    function hasImageElement(element) {
        var children = element.children;
        for (var i = 0; i < children.length; i++) {
            var childElement = children[i];
            if (childElement.tagName.toLowerCase() === 'img') {
                return true;
            }
            if (childElement.children.length > 0 && hasImageElement(childElement)) {
                return true;
            }
        }
        return false;
    }
}
