/*!
 *
 *  smooth-scroll.ts
 *
 */
var smoothScrollCore = function (target, distance, duration) {
    var startTime = null;
    var startPosition = window.pageYOffset;
    var animation = function (currentTime) {
        if (startTime === null) {
            startTime = currentTime;
        }
        var timeElapsed = currentTime - startTime;
        var run = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) {
            requestAnimationFrame(animation);
        }
    };
    var ease = function (t, b, c, d) {
        t /= d;
        return -c * Math.pow(2, -10 * t) + c + b;
    };
    requestAnimationFrame(animation);
};
var smoothScroll = function (options) {
    if (options === void 0) { options = {}; }
    var ignore = options.ignore || '[data-scroll-ignore]';
    var duration = options.duration || 700;
    var links = document.querySelectorAll("a[href^=\"#\"]:not(".concat(ignore, ")"));
    links.forEach(function (link) {
        link.addEventListener('click', function (e) {
            e.preventDefault();
            var target = document.querySelector(link.getAttribute('href'));
            if (target) {
                var targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
                var distance = targetPosition - window.pageYOffset - util.scrollGap;
                smoothScrollCore(target, distance, duration);
            }
        });
    });
};
export default smoothScroll;
